/* Global */
.text-page aside .widget           { margin: 0; }
.text-page aside .widget .content  { padding: 0 0 35px 0; }

/* Check Icons */
.check-icons          { margin: 0; list-style-type: none; padding: 0; }
.check-icons li       { float: left; width: 33%; margin: 0 0 22px 0; padding: 0; text-align: center; }
.check-icons label    { display: block; margin: 0; padding: 0; cursor: pointer; }
.check-icons .icn     { display: block; height: 70px; }
.check-icons .icn img { position: relative; top: 5px; }
.check-icons span     { display: block; height: 45px; padding: 0 8px; font-weight: 300; font-size: 16px; color: #666; }

/* Booking Widget */
.booking-widget             { border: 1px solid #ccc; border-top: none; background: #fff; padding: 0 18px 16px 18px; margin: -25px 0 12px 0 !important; }
.booking-widget header      { background: #22709b; color: #fff; margin: 0 -18px 18px -18px; padding: 13px 18px; }
.booking-widget .price      { font-size: 18px; font-weight: bold; }
.booking-widget .note       { font-size: 16px; font-weight: 300; }
.booking-widget p           { margin: 0; }
.booking-widget .actions    { text-align: center; margin: 0; padding: 0; }
.booking-widget .actions p  { margin: 12px 0 0 0; }
.booking-widget .btn-book   { font-weight: bold; font-size: 16px; }
.booking-widget .datepicker { background: #fff url(../img/icn_datepicker) no-repeat 100% 6px; }
.booking-widget .summary { margin-left: -18px; margin-right: -18px; }
.booking-widget .summary table { border-left: none; border-right: none; }
.booking-widget .summary table td { padding-left: 18px; }
.booking-widget .summary table td:first-child { border-left: none; }
.booking-widget .summary table td:last-child { border-right: none; }

/* Bookig Page Widget */
.booking-page-widget { border: 1px solid #ccc; background: #fff; padding: 0 18px 16px 18px; margin: 0 0 12px 0 !important; }
.booking-page-widget figure { margin: 0 -18px 15px -18px; }
.booking-page-widget h2 { font-size: 24px; margin: 0 0 3px 0; }
.booking-page-widget h5 { margin: 0 0 6px 0; }
.booking-page-widget p { margin: 0;font-size: 15px; }
.booking-page-widget hr { margin: 18px 0; }
.booking-page-widget .totals { margin-top: -10px; }
.booking-page-widget .totals hr { margin: 6px 0; }
.booking-page-widget .totals { color: #666; font-weight: 300; }
.booking-page-widget .totals .total { font-size: 22px }

/* Crew Options */
.crew-widget .icn-chef img { position: relative; top: 1px; }
.crew-widget .content      { padding: 0 !important; margin: 0 !important; }

/* Additional Boat Options */
.additional-options-widget {}
.additional-options-widget .content .checkbox { margin: 13px 0; }
.additional-options-widget .content label { font-size: 16px; font-weight: 300; }
