/* ! Navigation */
#nav               { float: right; padding-top: 20px; }
#nav > ul          { list-style-type: none; display: inline-block; }
#nav > ul > li     { display: inline-block; text-transform: uppercase; font-size: 12px; font-weight: bold; margin-left: 20px; position: relative; }
#nav > ul > li > a { display: inline-block; color: #fff; padding: 20px 0; }
#nav ul.pick li    { margin: 0; padding: 0 10px; }
#nav li.lang       { border-right: 1px solid #fff; }
#nav ul.pick       { position: relative; padding: 10px 0px 10px 40px; }
#nav ul.pick a     { padding: 0; }

	/* Simple Dropdown */
	#nav ul.drop                 { position: absolute; top: 50px; left: 50%; margin-left: -110px; background: #fff; width: 220px; padding: 12px 10px; display: none; list-style-type: none; }
	#nav > ul > li:hover ul.drop { display: block; }
	#nav ul.drop:before          { content: ""; border-left: 5px solid transparent; border-right: 5px solid transparent; border-bottom: 5px solid #fff; width: 0; height: 0; text-indent: -9999em; position: absolute; top: -5px; left: 50%; margin-left: -3px; }
	#nav ul.drop li              { color: #22709b; border-bottom: 1px solid #efefef; }
	#nav ul.drop a               { color: #22709b; display: block; padding: 6px 7px; margin: 2px 0; border-radius: 2px; }
	#nav ul.drop a:hover         { color: #fff; background: #22709b; text-decoration: none; }
	#nav ul.drop li:last-child   { border-bottom: none; }

	/* Language dropdown */
	#nav .pick .drop                { position: absolute; top: 50px; left: 50%; width: 210px; background: #fff; margin-left: -141px; padding: 12px 10px; display: none; }
	#nav .pick:hover .drop          { display: block; }
	#nav .pick .drop:before         { content: ""; border-left: 5px solid transparent; border-right: 5px solid transparent; border-bottom: 5px solid #fff; width: 0; height: 0; text-indent: -9999em; position: absolute; top: -5px; right: 40px; margin-left: -3px; }
	#nav .pick .drop ul             { list-style-type: none; margin: 0; padding: 0; }
	#nav .pick .drop li             { color: #22709b; border-bottom: 1px solid #efefef; margin: 0; padding: 0; }
	#nav .pick .drop a              { color: #22709b; display: block; padding: 6px 7px; margin: 2px 0; border-radius: 2px; position: relative; }
	#nav .pick .drop a:hover        { color: #fff; background: #22709b; text-decoration: none; }
	#nav .pick .drop li:last-child  { border-bottom: none; }
	#nav .pick .flag                { position: absolute; top: 50%; right: 10px; margin-top: -11px; }
	#nav .pick .flag img            { border: 1px solid #fff; border-radius: 2px; }

/* Simpler navigation */
#nav-simple    { text-align: center; }
#nav-simple ul { list-style-type: none; margin: 0; padding: 0; }
#nav-simple li { display: inline-block; text-transform: uppercase; font-size: 12px; font-weight: bold; margin: 0 17px; }
#nav-simple a  { display: inline-block; color: #fff; }

/* Icon navigation */
.icon-nav    { position: absolute; top: 140px; left: 0; }
.icon-nav ul { list-style-type: none; margin: 0; padding: 0; }
.icon-nav li { margin: 0; padding: 0; }
.icon-nav a  { display: block; width: 40px; height: 40px; margin: 0 0 2px 0; overflow: hidden; text-indent: -9999em; background: rgba(34,112,155,0.8) url(../img/header_icns.png) no-repeat 8px 8px; border-radius: 0 2px 2px 0; }
.icon-nav .find a { background-position: 8px 8px; }
.icon-nav .chat a { background-position: 8px -34px; }
.icon-nav .phone a { background-position: 8px -74px; }
.icon-nav .email a { background-position: 8px -117px; }
.icon-nav .skype a { background-position: 8px -159px; }
.icon-nav .facebook a { background-position: 8px -201px; }
.icon-nav .twitter a { background-position: 8px -243px; }
.icon-nav .google a { background-position: 8px -286px; }
