/* Container widths */
@media (max-width: 992px)  {
	.burger-menu { display: block; }
	#nav { display: none; clear: both; float: none; }

	#nav > ul          { display: block; margin: 0; padding: 0 0 10px 0; }
	#nav > ul > li     { display: block; }
	#nav > ul > li > a { display: block; padding: 5px 0; }

	#nav ul.drop                 { position: relative; top: 0; left: 0; margin-left: 0; background: transparent; width: auto; padding: 0 0 10px 0; display: block; list-style-type: none; }
	#nav > ul > li:hover ul.drop { display: block; }
	#nav ul.drop:before          { display: none; }
	#nav ul.drop li              { color: #fff; border-bottom: none; }
	#nav ul.drop a               { color: #fff; display: block; padding: 6px 7px 6px 25px; margin: 2px 0; }
	#nav ul.drop a:hover         { color: #fff; background: transparent; text-decoration: none; }
	#nav ul.drop li:last-child   { border-bottom: none; }

	#nav ul.pick       { position: relative; padding: 0; margin: 30px 0 15px 10px; }
	#nav ul.pick li    { margin: 0; padding: 0 10px; display: inline-block; }
	#nav li.lang       { border-right: 1px solid #fff; }
	#nav ul.pick a     { padding: 0; }

	#nav .pick .drop                { position: relative; top: 0; left: 0; width: auto; background: transparent; margin-left: 0; padding: 12px 10px; display: block; }
	#nav .pick .drop:before         { display: none; }
	#nav .pick .drop ul             { list-style-type: none; margin: 0; padding: 0; }
	#nav .pick .drop li             { color: #fff; border-bottom: none; margin: 0; padding: 0; display: block; }
	#nav .pick .drop a              { color: #fff; display: block; padding: 6px 7px 6px 30px; margin: 2px 0; border-radius: 2px; position: relative; }
	#nav .pick .drop a:hover        { color: #fff; background: transparent; text-decoration: underline; }
	#nav .pick .flag                { position: absolute; top: 50%; right: auto; left: 0; margin-top: -11px; }
	#nav .pick .flag img            { border: 1px solid #fff; border-radius: 2px; }

	.icon-nav    { top: 20px; }

	.welcome h1 { font-size: 44px; padding: 75px 40px 45px 40px; }
	.welcome h2 { font-size: 24px; }
	.welcome form { margin: 20px 30px 55px 30px; }
	.welcome form .form-group { margin-bottom: 0;}


	.popup { width: 100%; top: 0; left: 0; margin-left: 0; }

	.quick-links { padding-top: 15px; }
	.quick-links h5 { margin-top: 25px; }
	.quick-links ul { margin-bottom: 0px; }
}

@media (max-width: 768px)  {
	.welcome h1 { font-size: 44px; padding-top: 35px; font-size: 34px; }
	.welcome h2 { font-size: 20px; }
	.welcome form .form-group { margin-bottom: 10px;}
}


@media (min-width: 768px)  {
	.container-fluid { max-width: 750px; }
}


@media (min-width: 992px)  {
	.container-fluid { max-width: 940px; }
}


@media (min-width: 1200px) {
	.container-fluid { max-width: 940px; }
}


@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 2dppx)  {
    .more-filters .select2-container .select2-choice .select2-arrow b {
        background-image: url('../img/select2x2_white.png') !important;
    }
}
