body { font-family: 'Open Sans', sans-serif; }

/* Forms */
.form-control { font-size: 13px; }
.form-group.actions { margin-top: 30px; }
.form-group.actions .btn { min-width: 220px; font-size: 18px; }
input.datepicker { background: #fff url(../img/icn_datepicker) no-repeat 100% 6px; }
input[type=text], textarea { font-family: 'Open Sans', sans-serif; font-weight: 300; font-size: 14px !important; }

/* ! Containers */
.container { width: 940px; margin: 0 auto; }

/* Buttons */
.btn               { font-weight: normal; font-family: 'Open Sans', sans-serif; }
.btn-primary       { color: #fff; background: #ff8b00; border-color: #ff8b00; text-transform: uppercase; }
.btn-primary:hover { background: #ff7a00; border-color: #ff7a00; }
.btn-default       { color: #22709b; background: #fff; border-color: #22709b; text-transform: uppercase; padding: 8px 50px; font-size: 18px; }
.btn-default:hover { background: #fff; border-color: #22709b; }

/* ! Header */
#hd     { background: #0e3f5a; position: relative; z-index: 2; }
#logo   { height: 100px; margin: 0; padding: 0 0 0 40px; float: left; }
#logo a { display: block; padding: 30px 0 0 0; }
#hd-alt { background: #0e3f5a; padding: 12px 0; }

/* Filters */
.filters { padding: 10px 0 1px 0; background: #407fa2; }
.filters .form-group { margin-bottom: 9px; }

/* More Filters */
.more-filters { display: none; padding: 16px 0 0 0; margin: 0 0 10px 0; }
.more-filters hr { clear: both; }
.more-filters.active { display: block; }
.more-filters label { font-size: 14px; font-weight: bold; text-transform: uppercase; color: #666; margin: 0 0 18px 0; }
.more-filters .slider { width: 100%; }

	/* Boat dimensions */
	.boat-dimensions { padding-bottom: 10px; }
	.boat-dimensions .tooltip { top: 55px; }
	.boat-dimensions .tooltip  .tooltip-arrow { top: 0; left: 50%; margin-left: -5px; border-width: 0 5px 5px; border-bottom-color: #22709b; }

	/* Boat properties */
	.boat-properties .form-group { background: url(../img/icn_propeller.png) no-repeat 50% 0; }
	.boat-properties .form-group label { width: 100%; text-align: center; display: block; padding-top: 40px; margin: 0; font-weight: 300; font-size: 16px; text-transform: none; color: #666; cursor: pointer; }
	.boat-properties .form-group input[type=checkbox] { position: relative; top: 22px; }
	.boat-properties .pick-propeller { background-image: url(../img/icn_propeller.png); }
	.boat-properties .pick-ac { background-image: url(../img/icn_ac.png); }
	.boat-properties .pick-heating { background-image: url(../img/icn_heating.png); }
	.boat-properties .pick-tv { background-image: url(../img/icn_tv.png); }
	.boat-properties .pick-wifi { background-image: url(../img/icn_wifi.png); }
	.boat-properties .pick-wheel { background-image: url(../img/icn_wheel.png); }

/* ! Footer */
#f1 { background: #0e3f5a; padding: 25px 0 5px 0; }
#f1 .logo { padding: 10px 0 20px 0; }
#f1 p { font-weight: 300; font-size: 12px; margin: 0 0 20px; color: #fff; line-height: 150%; }

/* ! Slider */
.photo-slider               { position: relative; margin: 0 0 25px 0; z-index: 1; }
.photo-slider figure        { max-height: 500px; overflow: hidden; }
.photo-slider figure img    { width: 100%; }
.photo-slider .owl-controls { position: absolute; top: 50%; left: 0; width: 100%; margin-top: -36px; }
.photo-slider .owl-prev     { position: absolute; top: 0; left: 20px; width: 50px; height: 71px; overflow: hidden; text-indent: -9999em; background: transparent url(../img/icn_slider_nav.png) no-repeat top left !important; opacity: 1 !important; }
.photo-slider .owl-next     { position: absolute; top: 0; right: 20px; width: 50px; height: 71px; overflow: hidden; text-indent: -9999em; background: transparent url(../img/icn_slider_nav.png) no-repeat top right !important; opacity: 1 !important; }

/* Home Welcome */
.welcome    { background: #22709b url(../img/header/homepagePicBIG-1920x660.jpg) no-repeat top center; position: relative; z-index: 1; }
.welcome h1 { font-weight: 300; font-size: 60px; color: #fff; text-align: center; margin: 0; padding: 135px 0 105px 0; line-height: 135%; }
.welcome h2 { font-weight: 300; font-size: 24px; color: #fff; text-align: center; margin: 0; }

	.welcome .find { padding: 0; }
	.welcome form  { margin: 0; background: rgba(34,112,155,0.8); border-radius: 10px; padding: 14px 0; margin: 20px 0 155px 0; }
	.welcome form .form-group { margin: 0; }

/* ! Home offer */
.offer    { text-align: center; padding: 60px 0 35px 0; }
.offer h2 { font-weight: 300; font-size: 44px; color: #22709b; line-height: 140%; }

/* ! Help offer */
.help-offer    { text-align: center; padding: 45px 0 45px 0; background: #22709b; }
.help-offer h2 { font-weight: 300; margin: 22px 0 30px 0; font-size: 44px; color: #fff; line-height: 140%; }

/* ! About listing */
.about        { padding: 40px 0; }
.about h3     { font-weight: 300; font-size: 36px; margin: 0 0 24px 0; line-height: 130%; }
.about h4     { font-weight: 300; font-size: 26px; margin: 0 0 24px 0; line-height: 130%; }
.about p      { font-weight: 300; font-size: 16px; line-height: 170%; margin: 0 0 20px 0; }
.about img    { width: 100%; }
.about .entry { padding-bottom: 35px; }
.about hr     { margin: 0 0 35px 0; }

/* ! Testimonials */
.testimonials        { padding: 45px 0 45px 0; background: #22709b; }
.testimonials h4     { font-weight: 300; font-size: 24px; margin: 0 0 24px 0; color: #fff; line-height: 140%; }
.testimonials h4.sig { font-style: italic; }
.testimonials h5     { font-weight: 300; font-size: 14px; margin: 0 0 24px 0; color: #fff; text-transform: uppercase; line-height: 140%; }
.testimonials figure { margin-bottom: 12px; }

/* ! Special offers */
.special-offers {}
.special-offers .offer-item { margin-bottom: 26px; }
.special-offers h3 { font-size: 36px; line-height: 150%; text-align: center; font-weight: 300; color: #22709b; margin-bottom: 80px; }
.special-offers h4 { font-size: 24px; line-height: 140%; font-weight: 300; color: #666; }
.special-offers p { font-size: 16px; line-height: 150%; font-weight: 300; color: #666; }
.special-offers figure img { width: 100%; }

/* Social */
.social { margin: 0; padding: 0 0 45px 0; }
.social hr { margin: 20px 0 30px 0; }
.social h3 { font-size: 36px; line-height: 140%; font-weight: 300; color: #22709b; display: inline-block; margin: 0; }
.social ul { display: inline-block; list-style-type: none; margin: 0 0 0 6px; padding: 0; position: relative; top: 9px; }
.social li { display: inline-block; margin: 0; padding: 0; margin-right: 6px; }
.social li a { display: inline-block; width: 40px; height: 40px; text-indent: -9999em; overflow: hidden; background: url(../img/icns_social.png); }
.social li.facebook a  { background-position: 0 0; }
.social li.twitter a   { background-position: -50px 0; }
.social li.google a    { background-position: -100px 0; }
.social li.instagram a { background-position: -150px 0; }
.social li.skype a     { background-position: -200px 0; }
.social li.youtube a   { background-position: -250px 0; }
.social .fb_iframe_widget { padding-top: 16px; }
.social.fb_iframe_widget, .social.fb_iframe_widget span, .social.fb_iframe_widget span iframe[style] {
    width: 100% !important;
    height: 80px;
}

/* ! List links */
.list-links      { padding: 40px 0 20px 0; }
.list-links h5   { font-size: 14px; line-height: 140%; font-weight: bold; color: #22709b; padding: 0 0 14px 0; margin: 0 0 15px 0; border-bottom: 1px solid #cacaca; text-transform: uppercase; }
.list-links ul   { list-style-type: none; margin: 0 0 20px 0; padding: 0; font-size: 16px; font-weight: 300; }
.list-links li   { margin: 0; padding: 0; line-height: 30px; }
.list-links li a { color: #22709b; }
.list-links p    { margin: 0 0 12px 0; padding: 0; font-size: 16px; font-weight: 300; color: #22709b; line-height: 150%; }

/* Quick links */
.quick-links      { background: #22709b; padding: 40px 0; }
.quick-links h5   { font-size: 14px; line-height: 140%; font-weight: bold; color: #fff; padding: 0 0 14px 0; margin: 0 0 15px 0; border-bottom: 1px solid #90b7cd; text-transform: uppercase; }
.quick-links ul   { list-style-type: none; margin: 0 0 20px 0; padding: 0; font-size: 11px; font-weight: 300; }
.quick-links li   { margin: 0; padding: 0; line-height: 28px; }
.quick-links li a { color: #fff; }
.quick-links p    { margin: 0; padding: 0; font-size: 11px; font-weight: 300; color: #fff; line-height: 28px; }

/* Text Page Main Section */
.text-page .main      { margin: 0; }
.text-page table      { width: 100%; margin: 0 0 30px 0; }
.text-page h2         { font-weight: 300; font-size: 44px; color: #666; margin: 0 0 16px 0; line-height: 150%;  }
.text-page h3         { font-weight: 300; font-size: 34px; color: #666; margin: 0 0 16px 0; line-height: 150%;  }
.text-page h4         { font-weight: 300; font-size: 24px; color: #666; margin: 0 0 26px 0; line-height: 150%;  }
.text-page h5         { font-weight: bold; font-size: 14px; color: #666; margin: 0 0 16px 0; line-height: 150%; text-transform: uppercase; }
.text-page p          { font-weight: 300; font-size: 16px; color: #666; margin: 0 0 26px 0; line-height: 160%; }
.text-page figure     { margin: 0 0 26px 0; }
.text-page figure img { width: 100%; }
.text-page hr         { margin: 36px 0 26px 0; }

	/* Sharing */
	.text-page .main .share-tools    { margin: 0 0 40px 0; }
	.text-page .main .share-tools ul { list-style-type: none; margin: 0; padding: 0; }
	.text-page .main .share-tools li { display: inline-block; margin: 0 4px 0 0; padding: 0; }
	.text-page .main .share-tools a  { display: inline-block; background: url(../img/icns_social2.png) no-repeat; overflow: hidden; text-indent: -9999em; width: 35px; height: 38px; }
	.text-page .main .share-tools .facebook a { background-position: 0 0; }
	.text-page .main .share-tools .twitter a  { background-position: -45px 0; }
	.text-page .main .share-tools .google a   { background-position: -90px 0; }
	.text-page .main .share-tools .path a     { background-position: -135px 0; }

/* Text Page Sidebar */
.text-page aside                   {  }
.text-page aside th                { font-weight: 300; font-size: 16px; color: #666; margin: 0 0 26px 0; line-height: 160%; }
.text-page aside td                { font-weight: 300; font-size: 16px; color: #666; margin: 0 0 26px 0; line-height: 160%; }
.text-page aside .toggle           { margin: 0; padding: 12px 0 14px 0; cursor: pointer; background: url(../img/icn_toggle.png) no-repeat 100% 18px; }
.text-page aside .toggle.active    { background: url(../img/icn_toggle.png) no-repeat 100% -82px; }
.text-page aside .togglable        { display: none; }
.text-page aside .togglable.active { display: block; }

/* Location Map */
.location-map { height: 600px; background: #22709B; margin: 0 0 30px 0; }

/* Boats List */
.boat-list    { margin: 0 0 20px 0; }
.boat-list h5 { font-weight: bold; font-size: 14px; color: #666; margin: 0 0 26px 0; line-height: 150%; text-transform: uppercase; }

	/* Single boat item */
	.boat-list-item                   { padding: 0; margin: 0 0 30px 0; }
	.boat-list-item:hover             { background: #f0f0f0; outline: 10px solid #f0f0f0; }
	.boat-list-item figure            { margin: 0 0 12px 0; position: relative; }
	.boat-list-item figure img        { width: 100%; }
	.boat-list-item .price            { font-weight: bold; font-size: 18px; color: #fff; padding: 0 10px; position: absolute; bottom: 0; right: 0; background: rgba(51,51,51,0.8); line-height: 40px; white-space: nowrap; }
	.boat-list-item .price .old       { font-size: 14px; font-style: normal; padding-right: 8px; }
	.boat-list-item .price .old:after { content: "-"; position: absolute; top: 21px; left: 7px; width: 100%; height: 2px; overflow: hidden; display: block; width: 56px; background: #e52b00; -ms-transform: rotate(-7deg); -webkit-transform: rotate(-7deg); transform: rotate(-7deg); opacity: 0.9; }
	.boat-list-item h5                { font-weight: bold; font-size: 14px; color: #666; margin: 0 0 6px 0; line-height: 150%; text-transform: uppercase; }
	.boat-list-item h2                { font-weight: 300; font-size: 24px; color: #666; margin: 0 0 6px 0; line-height: 150%; }
	.boat-list-item p                 { font-weight: 300; font-size: 16px; color: #666; margin: 0 0 6px 0; line-height: 150%; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; }
	.boat-list-item a                 { color: #666; }
	.boat-list-item:hover a           { text-decoration: none; }
	.boat-list-item .tag              { font-weight: bold; font-size: 14px; color: #fff; padding: 0 10px; position: absolute; bottom: 0; left: 0; text-transform: uppercase; line-height: 40px; white-space: nowrap; }
	.boat-list-item .tag-green        { background: rgba(0,177,29,0.8); }
	.boat-list-item .tag-red          { background: rgba(229,43,0,0.8); }
	.boat-list-item .tag-yellow       { background: rgba(255,204,0,0.8); }

/* Boat Page */
.boat-page          {  }
.boat-page .main h2 { font-weight: 300; font-size: 24px; color: #666; margin: 0 0 6px 0; line-height: 150%; }
.boat-page .main h3 { font-weight: 300; font-size: 20px; color: #666; margin: 0 0 6px 0; line-height: 150%; }
.boat-page .main h4 { font-weight: 300; font-size: 16px; color: #666; margin: 0 0 6px 0; line-height: 150%; }
.boat-page .main th { font-weight: 300; font-size: 16px; color: #666; margin: 0 0 6px 0; line-height: 150%; }
.boat-page .main td { font-weight: 300; font-size: 16px; color: #666; margin: 0 0 6px 0; line-height: 150%; }

/* Boat Page Sidebar */
.boat-page aside    {}
.boat-page aside hr { margin: 0; }

/* Boat Info */
.boat-info                       { margin: 30px 0 40px 0; }
.boat-info span                  { font-weight: 300; font-size: 16px; color: #666; margin: 0 0 6px 0; line-height: 150%; display: block; }
.boat-info .icn                  { display: block; height: 50px; }
.boat-info img                   { position: relative; }
.boat-info .icn-cabin-number img { top: 0; }
.boat-info .icn-guest-number img { top: 8px; }
.boat-info .icn-boat-length img  { top: 16px; }
.boat-info .icn-engine img       { top: 5px; }

/* Search results */
.search-page    { padding-top: 30px; }
.search-page h3 { font-weight: 300; font-size: 36px; color: #4a88ab; }
.search-results {}
.search-results header { padding: 10px 0; margin: 0 0 10px 0; }
.search-results header p { color: #666; margin: 0; padding: 0; font-size: 16px; font-weight: 300; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; width: 100%; }
.search-results header p strong { font-size: 14px; text-transform: uppercase; font-weight: bold; }
.search-results .results {}

/* Booking form */
.disclaimer { background: url(../img/icn_info.png) no-repeat; padding: 5px 0 0 40px; }
.booking-page { padding-bottom: 70px; padding-top: 40px; }
.booking-form { padding-top: 15px; }
.booking-form label { font-weight: 300 !important; font-size: 16px; color: #666; }

/* Selecboxes */
.select2-drop { z-index: 2147483611; }
.select2-drop-mask { z-index: 2147483610; }
.select2-container .select2-chosen { position: relative; top: 1px; }
.more-filters .select2-container .select2-choice { background: #4a88ab; border-color: #4a88ab; color: #fff; }
.select2-search { padding: 5px; }
.more-filters .select2-container .select2-choice .select2-arrow b { background-image: url('../img/select2_white.png') !important; }

/* Cookie law theme */
.cc_banner-wrapper { z-index: 9001; position: relative; }
.cc_container { position: fixed; left: 15px; right: 15px; bottom: 15px; overflow: hidden; padding: 10px; border-radius: 10px; }
.cc_container { background: rgba(34,112,155,0.8) url(../img/icn_cookies.png) no-repeat 15px 15px; min-height: 90px; font-family: 'Open Sans', sans-serif; font-weight: 300; font-size: 16px; }
.cc_container, .cc_message, .cc_btn { animation-duration: 0.8s; -webkit-animation-duration: 0.8s; -moz-animation-duration: 0.8s; -o-animation-duration: 0.8s; -webkit-animation-name: slideUp; animation-name: slideUp; }
.cc_container .cc_logo { display: none; }
.cc_message { color: #fff; padding: 15px 200px 0 115px; margin: 0; }
.cc_btn { position: absolute; top: 29px; right: 40px; color: #22709b; font-weight: bold; text-transform: uppercase; background: #fff; border-radius: 7px; font-size: 16px; padding: 5px 30px; }

/* Range Slider */
.slider-handle { background: url(../img/bg_slider.png) no-repeat; filter: none; -webkit-box-shadow: none; box-shadow: none; border-radius: 0 !important; margin-top: -7px !important; }
.slider-track { background: #fff; filter: none; -webkit-box-shadow: none; box-shadow: none; border-radius: 2px; border: 2px solid #ccc; }
.slider-selection { position: absolute; background: #4a88ab; filter: none; -webkit-box-shadow: none; box-shadow: none; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; border-radius: 1px; }
.tooltip-inner { background-color: #22709b; border-radius: 2px; }
.tooltip.top .tooltip-arrow { border-top-color: #22709b; }

/* Popups */
.popup { display: none; position: fixed; margin: 0 auto; top: 120px; left: 50%; margin-left: -280px; width: 560px; background: #fff; z-index: 2147483601; padding: 20px 30px; }
.popup h5 { font-size: 14px; line-height: 140%; font-weight: bold; color: #666; text-transform: uppercase; margin-bottom: 30px; }
.popup label { font-weight: 300 !important; font-size: 14px; color: #666; }
.popup h4 { font-size: 24px; line-height: 36px; font-weight: 300; color: #666; }
.popup p { font-size: 16px; line-height: 36px; font-weight: 300; color: #666; }

	/* Find a boat */
	.popup.popup-find-boat { position: absolute; }
	.popup.popup-find-boat .steps { height: 21px; list-style-type: none; margin: 0; padding: 0; position: relative; margin: 0 0 35px 0; border-bottom: 1px solid #ccc; }
	.popup.popup-find-boat .steps li { width: 40px; height: 40px; border-radius: 50%; background: #cdcdcd; color: #fff; text-align: center; line-height: 40px; font-size: 16px; font-weight: bold; position: absolute; top: 0; }
	.popup.popup-find-boat .steps li.s1 { height: 40px; left: -12px; }
	.popup.popup-find-boat .step-1 h5 { margin-bottom: 14px; }
	.popup.popup-find-boat .boat-properties .form-group label { font-size: 15px; }
	.popup.popup-find-boat .step-1 li.s1 { background: #22709b; }
	.popup.popup-find-boat .steps li.s2 { height: 40px; left: 50%; margin-left: -20px; }
	.popup.popup-find-boat .step-2 { display: none; }
	.popup.popup-find-boat .step-2 li.s2 { background: #22709b; }
	.popup.popup-find-boat .steps li.s3 { height: 40px; right: -12px; }
	.popup.popup-find-boat .step-3 li.s3 { background: #22709b; }
	.popup.popup-find-boat .step-3 { text-align: center; display: none; }
	.popup.popup-find-boat .step-3 figure { margin-bottom: 50px; }
	.popup.popup-find-boat .step-3 h4 { margin-bottom: 30px; }
	.popup.popup-find-boat .slider { width: 100%; }
	.popup.popup-find-boat .tooltip { top: 55px; }
	.popup.popup-find-boat .tooltip  .tooltip-arrow { top: 0; left: 50%; margin-left: -5px; border-width: 0 5px 5px; border-bottom-color: #22709b; }
	.popup.popup-find-boat .select2-container .select2-choice { background: #4a88ab; border-color: #4a88ab; color: #fff; }
	.popup.popup-find-boat .select2-container .select2-choice .select2-arrow b { background-image: url('../img/select2_white.png') !important; }
	.popup.popup-find-boat .datepicker { background: #4a88ab url(../img/icn_datepicker_white.png) no-repeat 100% 11px; color: #fff; border: none; }

	/* Send message */
	.popup.popup-send-message { position: absolute; }

/* Burger Menu */
.burger-menu { position: absolute; top: 20px; right: 30px; width: 25px; height: 30px !important; z-index: 2147483610; cursor: pointer; }
.burger-menu i { display: block; height: 2px; background: #fff; margin-bottom: 5px; }
